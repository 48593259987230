import styled from "styled-components";
import { Link } from "gatsby";

export const Button = styled(Link)`
    background: ${({ Main }) => (Main ? '#6C72CB' : '#CB69C1')};
    white-space: nowrap;
    padding: ${({ Big }) => (Big ? '16px 40px' : '10px 32px')} ;
    color: #fff;
    font-size: ${({ Big }) => (Big ? '24px' : '16px')} ;
    outline: none;
    border: none;
    min-width: 100px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.5s !important;
    border-radius: ${({ Round }) => (Round ? '64px' : 'none')} ;

    &:hover{
    background: ${({ Main }) => (Main ? '#CB69C1' : '#6C72CB')};
    transform: translateY(-2px);
    }
`